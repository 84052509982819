import { Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import Spinner from './components/Spinner';
import Error from './components/Error';
import Router from './router';
import styles from './App.module.css';

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  return <Error onClick={resetErrorBoundary} errorType="other" />;
};

function App() {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
          <Suspense fallback={<Spinner className={styles.app__spinner} />}>
            <Router />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

export default App;

import classNames from 'classnames';
import SimpleHeader from '../SimpleHeader/SimpleHeader';
import Button from '../Button/Button';
import Title from '../Title/Title';
import styles from './Error.module.css';

const defaultErrorMessage = (
  <>
    Что-то пошло не так. <br /> На данный момент функция недоступна.
  </>
);

interface Props {
  errorMessage?: React.ReactNode;
  buttonText?: string;
  errorType?: 'not-found' | 'other';
  onClick?: () => void;
}
const Error = ({
  buttonText = 'Попробовать еще раз',
  errorMessage = defaultErrorMessage,
  errorType = 'other',
  onClick,
}: Props) => {
  const handleButtonClick = () => {
    onClick?.();
  };

  return (
    <div
      className={classNames(styles.error, {
        [styles['error--not-found']]: errorType === 'not-found',
      })}
    >
      <SimpleHeader className={styles.error__header} />
      <main className={styles.error__content}>
        <section className={styles.error__message}>
          <Title as="h1" className={styles.error__title}>
            {errorMessage}
          </Title>
          <Button className={styles.error__button} onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </section>
      </main>
    </div>
  );
};
export default Error;

import classNames from 'classnames';
import styles from './Button.module.css';

interface Props {
  children: React.ReactNode;
  as?: 'button' | 'a';
  className?: string;
  href?: string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}
const Button = ({
  icon,
  as = 'button',
  href,
  children,
  onClick,
  className,
  disabled,
  type = 'button',
  size = 'large',
}: Props) => {
  const Component = as;
  return (
    <Component
      className={classNames(
        styles.button,
        {
          [styles['button--small']]: size === 'small',
          [styles['button--medium']]: size === 'medium',
          [styles['button--with-icon']]: !!icon,
          [styles['button--empty']]: !children,
        },
        className
      )}
      onClick={onClick}
      type={as === 'button' ? type : undefined}
      href={href}
      disabled={disabled}
    >
      {icon}
      {children}
    </Component>
  );
};
export default Button;

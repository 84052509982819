import ButtonIcon from '../ButtonIcon';
import Avatar from '../Avatar';
import Logo from '../Logo';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/log-out.svg';
import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import styles from './HeaderTablet.module.css';

interface Props {
  avatarUrl?: string;
  onMenuClick?: () => void;
  onAvatarClick?: () => void;
  onSettingsClick?: () => void;
  onLogOutClick?: () => void;
}
const HeaderTablet = ({
  avatarUrl,
  onMenuClick,
  onAvatarClick,
  onSettingsClick,
  onLogOutClick,
}: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.header__left}>
        <Logo />
        <ButtonIcon size="medium" appearance="secondary" onClick={onMenuClick}>
          <MenuIcon />
        </ButtonIcon>
      </div>
      <div className={styles.header__right}>
        <Avatar
          className={styles.header__button}
          onClick={onAvatarClick}
          imageUrl={avatarUrl}
        />
        <ButtonIcon
          appearance="secondary"
          size="medium"
          onClick={onSettingsClick}
          className={styles.header__button}
        >
          <SettingsIcon />
        </ButtonIcon>
        <ButtonIcon
          appearance="secondary"
          size="medium"
          onClick={onLogOutClick}
          className={styles.header__button}
        >
          <LogOutIcon />
        </ButtonIcon>
      </div>
    </div>
  );
};
export default HeaderTablet;

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import Image from '../Image';
import DefaultAvatar from '../../images/png/default-avatar.png';
import styles from './Avatar.module.css';

interface Props {
  size?: 'small' | 'medium' | 'large';
  imageUrl?: string;
  withBorder?: boolean;
  className?: string;
  onClick?: () => void;
}
const Avatar = ({
  size = 'medium',
  imageUrl,
  withBorder,
  className,
  onClick,
}: Props) => {
  const isInteractive = !!onClick;
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isInteractive) return;
    if (event.key === 'Enter') {
      onClick();
    }
  };

  // Add interactive props if onClick is provided
  const interactiveProps = isInteractive && {
    role: 'button',
    tabIndex: 0,
    onClick,
    onKeyDown: handleKeyDown,
  };

  return (
    <div
      className={classNames(
        styles.avatar,
        {
          [styles['avatar--small']]: size === 'small',
          [styles['avatar--large']]: size === 'large',
          [styles['avatar--border']]: withBorder,
          [styles['avatar--interactive']]: isInteractive,
        },
        className
      )}
      {...interactiveProps}
    >
      <div className={styles.avatar__content}>
        <Image
          className={styles.avatar__img}
          src1x={imageUrl || DefaultAvatar}
          alt="avatar"
        />
      </div>
    </div>
  );
};
export default Avatar;

import classNames from 'classnames';
import Profile from '../Profile';
import styles from './ProfileSidebar.module.css';

interface Props {
  className?: string;
}
const ProfileSidebar = ({ className }: Props) => {
  return (
    <aside className={classNames(styles['profile-sidebar'], className)}>
      <Profile />
    </aside>
  );
};
export default ProfileSidebar;

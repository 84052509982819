import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import ButtonIcon from '../ButtonIcon';
import Avatar from '../Avatar';
import Logo from '../Logo';
import styles from './HeaderMobile.module.css';

interface Props {
  avatarUrl?: string;
  onMenuClick?: () => void;
  onAvatarClick?: () => void;
}
const HeaderMobile = ({ onMenuClick, onAvatarClick, avatarUrl }: Props) => {
  return (
    <div className={styles.header}>
      <ButtonIcon size="medium" appearance="secondary" onClick={onMenuClick}>
        <MenuIcon />
      </ButtonIcon>
      <Logo />
      <Avatar onClick={onAvatarClick} imageUrl={avatarUrl} />
    </div>
  );
};
export default HeaderMobile;

import classNames from 'classnames';
import styles from './IconButton.module.css';

interface Props {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}
const IconButton = ({ onClick, children, disabled, className }: Props) => {
  return (
    <button
      className={classNames(styles.button, className)}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default IconButton;

import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import client from '../api/apiSkillPro';

interface LogoutResponse {
  message: string;
}

const useLogout = (
  options?: Omit<
    UseMutationOptions<LogoutResponse, AxiosError, void, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(async () => {
    const { data } = await client.post<LogoutResponse>('/api/logout');
    return data;
  }, options);
};

export default useLogout;

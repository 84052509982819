/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import styles from './Backdrop.module.css';

interface Props {
  onClick?: () => void;
  className?: string;
}
const Backdrop = ({ onClick, className }: Props) => {
  return (
    <div className={classNames(styles.backdrop, className)} onClick={onClick} />
  );
};
export default Backdrop;

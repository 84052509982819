/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { UseFormRegister, RegisterOptions, FieldErrors } from 'react-hook-form';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { ReactComponent as WarningIcon } from '../../images/icons/warning.svg';
import styles from './Input.module.css';

interface Props {
  name: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  value?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  label?: string;
  color?: 'green' | 'red' | 'yellow';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  button?: React.ReactNode | ((disabled?: boolean) => JSX.Element);
  autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  register?: UseFormRegister<any>;
  registerOptions?: RegisterOptions;
  errors?: FieldErrors;
}
const Input = ({
  name,
  type,
  label,
  color,
  value,
  placeholder,
  iconLeft,
  iconRight,
  button,
  className,
  disabled,
  readOnly,
  onClick,
  onChange,
  defaultValue,
  autoComplete,
  register,
  registerOptions,
  errors,
}: Props) => {
  const error = errors?.[name];

  // if register is provided, use register else use our onChange
  const registerProps = register
    ? register(name, registerOptions)
    : {
        onChange,
      };

  return (
    <div className={classNames(styles.input__wrapper, className)}>
      <div
        className={classNames(styles.input, {
          [styles['input--disabled']]: disabled,
          [styles['input--green']]: color === 'green',
          [styles['input--red']]: color === 'red' || error,
          [styles['input--yellow']]: color === 'yellow',
          [styles['input--button']]: !!button,
          [styles['input--clickable']]: !!onClick,
        })}
        onClick={onClick}
        role="button"
      >
        {iconLeft && (
          <div
            className={classNames(
              styles.input__icon,
              styles['input__icon--left']
            )}
          >
            {iconLeft}
          </div>
        )}
        <div className={styles.input__content}>
          {label && (
            <label className={styles.input__label} htmlFor={name}>
              {label}
            </label>
          )}
          <input
            id={name}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            className={styles.input__field}
            readOnly={readOnly}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            aria-invalid={!!error}
            {...registerProps}
          />
        </div>
        {iconRight && (
          <div
            className={classNames(
              styles.input__icon,
              styles['input__icon--right']
            )}
          >
            {iconRight}
          </div>
        )}
        {button && button instanceof Function ? button(disabled) : button}
      </div>
      {error && error.message && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className={classNames(styles.input__error)}
        >
          <WarningIcon className={styles['input__error-icon']} />
          <p className={styles['input__error-text']}>
            {error.message.toString()}
          </p>
        </motion.div>
      )}
    </div>
  );
};
export default Input;

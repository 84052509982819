/* eslint-disable react/jsx-props-no-spreading */
interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {
  src1x: string;
  src2x?: string;
  alt: string;
}
const Image = ({ src1x, src2x, alt, ...rest }: Props) => {
  if (src2x) {
    return (
      <img src={src1x} srcSet={`${src1x}, ${src2x} 2x`} alt={alt} {...rest} />
    );
  }
  return <img src={src1x} alt={alt} {...rest} />;
};
export default Image;

import { Suspense } from 'react';
import { useMedia } from 'react-use';
import { Outlet } from 'react-router-dom';
import Spinner from '../Spinner';
import AuthHeader from '../SimpleHeader';
import styles from './AuthLayout.module.css';

interface Props {}
const AuthLayout = (props: Props) => {
  const isDesktop = useMedia('(min-width: 1600px)');
  return (
    <div className={styles['auth-layout']}>
      <div className={styles['auth-layout__container']}>
        <AuthHeader />
        <main className={styles['auth-layout__content']}>
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
      {isDesktop && <div className={styles['auth-layout__illustration']} />}
    </div>
  );
};
export default AuthLayout;

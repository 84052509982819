import classNames from 'classnames';
import ButtonIcon from '../ButtonIcon';
import { ReactComponent as InstagramIcon } from '../../images/icons/instagram.svg';
import { ReactComponent as TelegramIcon } from '../../images/icons/telegram.svg';
import { ReactComponent as VkIcon } from '../../images/icons/vk.svg';
import Illustration1xPng from '../../images/png/illustration-share.png';
import Illustration2xPng from '../../images/png/illustration-share@2x.png';
import Illustration1xWebp from '../../images/webp/illustration-share.webp';
import Illustration2xWebp from '../../images/webp/illustration-share@2x.webp';
import ImageWebp from '../ImageWebp';
import styles from './SocialWidgetBig.module.css';

interface Props {
  className?: string;
}
const SocialWidgetBig = ({ className }: Props) => {
  return (
    <div className={classNames(styles['social-widget'], className)}>
      <div className={styles['social-widget__container']}>
        <ImageWebp
          src1x={Illustration1xWebp}
          src2x={Illustration2xWebp}
          srcFallback1x={Illustration1xPng}
          srcFallback2x={Illustration2xPng}
          alt="Иллюстрация"
          className={styles['social-widget__image']}
        />
        <div className={styles['social-widget__content']}>
          <p className={styles['social-widget__text']}>
            Делитесь c нами в соц. сетях!
          </p>
          <div>
            <ButtonIcon
              as="a"
              href="https://instagram.com/skillproquest?igshid=YmMyMTA2M2Y="
              target="_blank"
              appearance="ghost"
              size="medium"
              className={styles['social-widget__button']}
            >
              <InstagramIcon className={styles.icon} />
            </ButtonIcon>
            <ButtonIcon
              as="a"
              href="https://t.me/SkillProQuest"
              target="_blank"
              appearance="ghost"
              size="medium"
              className={styles['social-widget__button']}
            >
              <TelegramIcon className={styles.icon} />
            </ButtonIcon>
            <ButtonIcon
              as="a"
              href="https://vk.com/skillprogame"
              target="_blank"
              appearance="ghost"
              size="medium"
              className={styles['social-widget__button']}
            >
              <VkIcon className={styles.icon} />
            </ButtonIcon>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialWidgetBig;

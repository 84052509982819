import classNames from 'classnames';
import { useMedia } from 'react-use';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header';
import MenuSidebar from '../MenuSidebar';
import ProfileSidebar from '../ProfileSidebar';
import styles from './Layout.module.css';

interface Props {}
const Layout = (props: Props) => {
  const location = useLocation();
  const isSettings = location.pathname === '/settings';
  const isDesktop = useMedia('(min-width: 1600px)');
  const showProfile = isDesktop && !isSettings;

  return (
    <div
      className={classNames(styles.layout, {
        [styles['layout--settings']]: isSettings,
      })}
    >
      {isDesktop && <MenuSidebar className={styles.layout__menu} />}
      <div className={styles.layout__container}>
        <Header />
        <main className={styles.layout__content}>
          <div className={styles['layout__main-content']}>
            <Outlet />
          </div>
          {showProfile && <ProfileSidebar />}
        </main>
      </div>
    </div>
  );
};
export default Layout;

import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Card from '../Card';
import styles from './Profile.module.css';
import ButtonIcon from '../ButtonIcon';
import Avatar from '../Avatar';
import Wallet from '../Wallet';
import ReferalLink from '../ReferalLink';
import BonusCard from '../BonusCard';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/log-out.svg';
import useUserQuery from '../../hooks/useUserQuery';
import useAvatarQuery from '../../hooks/useAvatarQuery';
import { getAvatar, getUserBonuses } from '../../utils/data';

interface Props {
  onClose?: () => void;
  onSettingsClick?: () => void;
  onLogOutClick?: () => void;
  className?: string;
}
const Profile = ({
  onSettingsClick,
  onLogOutClick,
  onClose,
  className,
}: Props) => {
  const navigate = useNavigate();
  const isTablet = useMedia('(min-width: 768px)');
  const showSettingButtons = !isTablet;

  const { data: user } = useUserQuery();
  const { data: avatarArr } = useAvatarQuery();

  if (!user || !avatarArr) return null;

  const avatar = getAvatar(avatarArr);
  const bonuses = getUserBonuses(user);
  const refLink = `${process.env.REACT_APP_WEB_URL}/registration?ref=${user.ref_code}`;

  const handleWalletClick = () => {
    if (onClose) onClose();
    navigate('/transactions');
  };

  return (
    <div className={classNames(styles.profile, className)}>
      <Card className={styles.profile__card}>
        <div className={styles.profile__info}>
          <div className={styles.profile__header}>
            {showSettingButtons && (
              <ButtonIcon
                appearance="secondary"
                size="medium"
                onClick={onSettingsClick}
              >
                <SettingsIcon />
              </ButtonIcon>
            )}
            <Avatar size="large" withBorder imageUrl={avatar.avatar} />
            {showSettingButtons && (
              <ButtonIcon
                appearance="secondary"
                size="medium"
                onClick={onLogOutClick}
              >
                <LogOutIcon />
              </ButtonIcon>
            )}
          </div>
          <div className={styles.profile__content}>
            <p
              className={styles.profile__title}
            >{`${user.first_name} ${user.last_name}`}</p>
            <Wallet
              usdtValue={user.usdt_balance}
              skillCoinValue={user.skillcoin_balance}
              className={styles.profile__wallet}
              onClick={handleWalletClick}
            />
            <ReferalLink linkUrl={refLink} />
          </div>
        </div>
      </Card>
      <div className={styles['profile__bonus-cards-wrapper']}>
        {bonuses.map(({ id, image, title, value, currency }) => (
          <BonusCard
            key={id}
            image={image}
            title={title}
            value={value}
            currency={currency}
            className={styles['profile__bonus-card']}
          />
        ))}
      </div>
    </div>
  );
};
export default Profile;

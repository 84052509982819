import classNames from 'classnames';
import styles from './Title.module.css';

interface Props {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
  children: React.ReactNode;
  className?: string;
}
const Title = ({ as, children, className }: Props) => {
  const Component = as;
  return (
    <Component className={classNames(styles.title, className)}>
      {children}
    </Component>
  );
};
export default Title;

import PrizeIcon1xPng from '../images/png/icon-prize.png';
import PrizeIcon2xPng from '../images/png/icon-prize@2x.png';
import RegIcon1xPng from '../images/png/icon-reg.png';
import RegIcon2xPng from '../images/png/icon-reg@2x.png';
import ExIcon1xPng from '../images/png/icon-ex.png';
import ExIcon2xPng from '../images/png/icon-ex@2x.png';

export interface Bonus {
  id: number;
  type: 'reg_count' | 'clicks_count' | 'rewards';
  image: {
    src1x: string;
    src2x: string;
  };
  title: string;
  value: string | number;
  info?: string;
  currency?: 'SK' | 'USDT';
}

const bonuses: Bonus[] = [
  {
    id: 0,
    type: 'rewards',
    image: {
      src1x: PrizeIcon1xPng,
      src2x: PrizeIcon2xPng,
    },
    title: 'Вознаграждение',
    value: 0,
    currency: 'SK',
  },
  {
    id: 1,
    type: 'reg_count',
    image: {
      src1x: RegIcon1xPng,
      src2x: RegIcon2xPng,
    },
    title: 'Регистраций',
    value: 0,
    info: 'Количество регистраций в вашей сети',
  },
  {
    id: 2,
    type: 'clicks_count',
    image: {
      src1x: ExIcon1xPng,
      src2x: ExIcon2xPng,
    },
    title: 'Переходов',
    value: 0,
    info: 'Количество переходов в вашей сети',
  },
];

export default bonuses;

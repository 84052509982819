import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import client from '../api/apiSkillPro';

export interface Avatar {
  id: number;
  user_id: number;
  avatar: string;
}

const useAvatarQuery = () => {
  return useQuery<Avatar[], AxiosError>({
    queryKey: ['avatar'],
    queryFn: async () => {
      const { data } = await client.get<Avatar[]>('/avatar/');
      return data;
    },
    staleTime: 5 * 60 * 1000,
  });
};

export default useAvatarQuery;

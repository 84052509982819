import classNames from 'classnames';
import ButtonIcon from '../ButtonIcon';
import { ReactComponent as WalletIcon } from '../../images/icons/wallet.svg';
import { ReactComponent as SkillCoinIcon } from '../../images/icons/skillcoin.svg';
import { ReactComponent as UsdtIcon } from '../../images/icons/usdt.svg';
import { formatValue } from '../../utils/number';
import styles from './Wallet.module.css';

interface Props {
  usdtValue?: number;
  skillCoinValue?: number;
  className?: string;
  onClick?: () => void;
}

const Wallet = ({
  usdtValue = 0,
  skillCoinValue = 0,
  className,
  onClick,
}: Props) => {
  return (
    <div className={classNames(styles.wallet, className)}>
      <div className={styles.wallet__content}>
        <div className={styles.wallet__currency}>
          <span className={styles['wallet__currency-title']}>
            SkillCoin wallet
          </span>
          <div className={styles['wallet__currency-value']}>
            <SkillCoinIcon className={styles['wallet__currency-icon']} />
            <span className={styles['wallet__currency-text']}>
              {formatValue(skillCoinValue)}
            </span>
          </div>
        </div>
        <div className={styles.wallet__currency}>
          <span className={styles['wallet__currency-title']}>USDT wallet</span>
          <div>
            <UsdtIcon className={styles['wallet__currency-icon']} />
            <span className={styles['wallet__currency-text']}>
              {formatValue(usdtValue)}
            </span>
          </div>
        </div>
      </div>
      <ButtonIcon appearance="secondary" onClick={onClick}>
        <WalletIcon />
      </ButtonIcon>
    </div>
  );
};
export default Wallet;

import { useState } from 'react';
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';
import HeaderMobile from './HeaderMobile';
import HeaderTablet from './HeaderTablet';
import HeaderDesktop from './HeaderDesktop';
import MenuDrawer from '../MenuDrawer';
import ProfileDrawer from '../ProfileDrawer';
import useUserQuery from '../../hooks/useUserQuery';
import useAvatarQuery from '../../hooks/useAvatarQuery';
import useLogout from '../../hooks/useLogout';
import { getAvatar } from '../../utils/data';

interface Props {}
const Header = (props: Props) => {
  const { resetToken } = useAuth();
  const navigate = useNavigate();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const isMobile = useMedia('(max-width: 767px)');
  const isTablet = useMedia('(min-width: 768px) and (max-width: 1599px)');
  const isDesktop = useMedia('(min-width: 1600px)');
  const queryClient = useQueryClient();

  const { data: user } = useUserQuery();
  const { data: avatarArr } = useAvatarQuery();
  const { mutate: logout } = useLogout({
    onSettled: () => {
      resetToken();
      queryClient.clear();
      navigate('/login');
    },
  });

  if (!user || !avatarArr) return null;

  const avatar = getAvatar(avatarArr);

  const handleMenuClick = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  const handleAvatarClick = () => {
    setIsProfileOpen(!isNavigationOpen);
  };

  const handleNavigationClose = () => {
    setIsNavigationOpen(false);
  };

  const handleProfileClose = () => {
    setIsProfileOpen(false);
  };

  const handleSettingsClick = () => {
    handleProfileClose();
    navigate('/settings');
  };

  const handleLogOutClick = () => {
    logout();
  };

  return (
    <header>
      {isMobile && (
        <HeaderMobile
          avatarUrl={avatar.avatar}
          onMenuClick={handleMenuClick}
          onAvatarClick={handleAvatarClick}
        />
      )}
      {isTablet && (
        <HeaderTablet
          avatarUrl={avatar.avatar}
          onMenuClick={handleMenuClick}
          onAvatarClick={handleAvatarClick}
          onSettingsClick={handleSettingsClick}
          onLogOutClick={handleLogOutClick}
        />
      )}
      {isDesktop && (
        <HeaderDesktop
          avatarUrl={avatar.avatar}
          user={user}
          onSettingsClick={handleSettingsClick}
          onLogOutClick={handleLogOutClick}
        />
      )}
      <MenuDrawer isOpened={isNavigationOpen} onClose={handleNavigationClose} />
      <ProfileDrawer
        isOpened={isProfileOpen}
        onClose={handleProfileClose}
        onSettingsClick={handleSettingsClick}
        onLogOutClick={handleLogOutClick}
      />
    </header>
  );
};
export default Header;

import { useState } from 'react';
import classNames from 'classnames';
import Menu from '../Menu/Menu';
import SocialWidget from '../SocialWidget';
import Logo from '../Logo';
import { ReactComponent as ArrowLeft } from '../../images/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../images/icons/arrow-right.svg';
import styles from './MenuSidebar.module.css';

interface Props {
  className?: string;
}
const MenuSidebar = ({ className }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };
  return (
    <aside
      className={classNames(
        styles['menu-sidebar'],
        {
          [styles['menu-sidebar--collapsed']]: isCollapsed,
        },
        className
      )}
    >
      <Logo
        size={isCollapsed ? 'small' : 'big'}
        className={styles['menu-sidebar__logo']}
      />
      <Menu
        size={isCollapsed ? 'small' : 'big'}
        className={styles['menu-sidebar__menu']}
      />
      <SocialWidget
        size={isCollapsed ? 'small' : 'big'}
        className={styles['menu-sidebar__social-widget']}
      />
      <button
        className={styles['menu-sidebar__collapse-button']}
        type="button"
        onClick={handleCollapse}
      >
        {isCollapsed ? <ArrowRight /> : <ArrowLeft />}
      </button>
    </aside>
  );
};
export default MenuSidebar;

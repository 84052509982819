import classNames from 'classnames';
import styles from './ButtonIcon.module.css';

interface Props {
  children: React.ReactNode;
  as?: 'button' | 'a';
  href?: string;
  target?: string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  appearance?: 'primary' | 'secondary' | 'ghost';
  disabled?: boolean;
  type?: 'button' | 'submit';
  className?: string;
}

const ButtonIcon = ({
  as = 'button',
  href,
  target,
  children,
  onClick,
  size = 'large',
  appearance = 'primary',
  type = 'button',
  disabled,
  className,
}: Props) => {
  const Component = as;

  return (
    <Component
      className={classNames(
        styles.button,
        {
          [styles['button--small']]: size === 'small',
          [styles['button--medium']]: size === 'medium',
          [styles['button--secondary']]: appearance === 'secondary',
          [styles['button--ghost']]: appearance === 'ghost',
        },
        className
      )}
      type={as === 'button' ? type : undefined}
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Component>
  );
};

export default ButtonIcon;

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import menuItems from '../../configs/menu';
import styles from './Menu.module.css';

interface Props {
  size?: 'big' | 'small';
  // activeUrl?: string;
  onClick?: () => void;
  className?: string;
}
const Menu = ({ size = 'big', onClick, className }: Props) => {
  return (
    <nav className={classNames(styles.menu, className)}>
      <ul className={styles.menu__links}>
        {menuItems.map(({ id, icon: Icon, url, text }) => (
          <li key={id} className={styles.menu__item}>
            <NavLink
              to={url}
              onClick={onClick}
              className={({ isActive }) =>
                classNames(styles.menu__link, {
                  [styles['menu__link--active']]: isActive,
                })
              }
            >
              <div className={styles['menu__link-icon']}>
                <Icon />
              </div>
              {size === 'big' && (
                <span className={styles['menu__link-text']}>{text}</span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default Menu;

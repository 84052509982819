import { useRef } from 'react';
import classNames from 'classnames';
import { Portal } from 'react-portal';
import useLockBodyScroll from '../../hooks/useLockBodyScroll';
import ButtonIcon from '../ButtonIcon';
import Container from '../Container';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import styles from './Drawer.module.css';
import Backdrop from '../Backdrop';

interface Props {
  children: React.ReactNode;
  onClose?: () => void;
  position?: 'left' | 'right';
  width?: string;
  isOpened?: boolean;
}

// TODO: Remove container
const Drawer = ({
  children,
  onClose,
  position = 'left',
  width,
  isOpened,
}: Props) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  useLockBodyScroll(drawerRef, isOpened);

  return isOpened ? (
    <Portal node={document && document.getElementById('drawer-root')}>
      <div
        ref={drawerRef}
        className={classNames(styles.drawer, {
          [styles['drawer--right']]: position === 'right',
        })}
        style={{ width }}
      >
        <Container className={styles.drawer__container}>
          <div className={styles['drawer__close-button']}>
            <ButtonIcon appearance="secondary" size="medium" onClick={onClose}>
              <CloseIcon />
            </ButtonIcon>
          </div>
          {children}
        </Container>
      </div>
      {width && <Backdrop onClick={onClose} />}
    </Portal>
  ) : null;
};
export default Drawer;

import classNames from 'classnames';
import { ReactComponent as InfoIcon } from '../../images/icons/info-square.svg';

import styles from './Info.module.css';

interface Props {
  text: string;
  position?: 'left' | 'right';
  className?: string;
}
function Info({ text, className, position = 'left' }: Props) {
  return (
    <span
      className={classNames(
        styles.tooltip,
        {
          [styles['tooltip--left']]: position === 'left',
        },
        className
      )}
      data-text={text}
    >
      <InfoIcon />
    </span>
  );
}

export default Info;

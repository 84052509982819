import Drawer from '../Drawer';
import Profile from '../Profile';

interface Props {
  onClose?: () => void;
  isOpened?: boolean;
  onSettingsClick?: () => void;
  onLogOutClick?: () => void;
}
const ProfileDrawer = ({
  onClose,
  isOpened,
  onSettingsClick,
  onLogOutClick,
}: Props) => {
  return (
    <Drawer isOpened={isOpened} onClose={onClose}>
      <Profile
        onSettingsClick={onSettingsClick}
        onLogOutClick={onLogOutClick}
        onClose={onClose}
      />
    </Drawer>
  );
};
export default ProfileDrawer;

/* eslint-disable react/jsx-props-no-spreading */
interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {
  src1x: string;
  src2x?: string;
  srcFallback1x: string;
  srcFallback2x?: string;
  alt: string;
  type?: string;
}

// Используем webp для уменьшения размера больших изображений
const ImageWebp = ({
  src1x,
  src2x,
  srcFallback1x,
  srcFallback2x,
  alt,
  type = 'image/webp',
  ...rest
}: Props) => {
  if (src2x && srcFallback2x) {
    return (
      <picture>
        <source srcSet={`${src1x}, ${src2x} 2x`} type={type} />
        <img
          src={srcFallback1x}
          srcSet={`${srcFallback1x}, ${srcFallback2x} 2x`}
          alt={alt}
          {...rest}
        />
      </picture>
    );
  }

  return (
    <picture>
      <source srcSet={src1x} type={type} />
      <img src={srcFallback1x} alt={alt} {...rest} />
    </picture>
  );
};
export default ImageWebp;

import classNames from 'classnames';
import Logo from '../Logo';
import styles from './SimpleHeader.module.css';

interface Props {
  className?: string;
}
const SimpleHeader = ({ className }: Props) => {
  return (
    <header className={classNames(styles['simple-header'], className)}>
      <Logo />
    </header>
  );
};
export default SimpleHeader;

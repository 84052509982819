/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { toast } from 'react-toastify';
import ButtonIcon from '../ButtonIcon';
import Input from '../Input';
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';
import copyToClipboard from '../../utils/copyToClipboard';

interface Props {
  linkUrl: string;
  className?: string;
}
const ReferalLink = ({ className, linkUrl }: Props) => {
  const handleClick = () => {
    toast.success('Адресс реферальной ссылки был успешно скопирован!');
    copyToClipboard(linkUrl);
  };
  return (
    <Input
      name="referalLink"
      label="Реферальная ссылка"
      className={className}
      value={linkUrl}
      readOnly
      button={
        <ButtonIcon appearance="secondary" onClick={handleClick}>
          <CopyIcon />
        </ButtonIcon>
      }
    />
  );
};
export default ReferalLink;

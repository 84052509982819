import { RefObject, useEffect } from 'react';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

const useLockBodyScroll = (
  target: RefObject<HTMLElement>,
  isLocked?: boolean
) => {
  useEffect(() => {
    if (target.current) {
      if (isLocked) {
        disableBodyScroll(target.current);
      } else {
        enableBodyScroll(target.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isLocked, target]);
};

export default useLockBodyScroll;

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import styles from './Card.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}
const Card = ({ children, className, ...rest }: Props) => {
  return (
    <div className={classNames(styles.card, className)} {...rest}>
      {children}
    </div>
  );
};
export default Card;

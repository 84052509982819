import SocialWidgetBig from './SocialWidgetBig';
import SocialWidgetSmall from './SocialWidgetSmall';

interface Props {
  size?: 'big' | 'small';
  className?: string;
}
const SocialWidget = ({ size = 'big', className }: Props) => {
  return size === 'big' ? (
    <SocialWidgetBig className={className} />
  ) : (
    <SocialWidgetSmall className={className} />
  );
};
export default SocialWidget;

import classNames from 'classnames';
import styles from './Container.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

function Container({ children, className }: Props) {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}

export default Container;

import { lazy } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import RouteNames from './RouteNames';

import Layout from '../components/Layout';
import AuthLayout from '../components/AuthLayout';
import RequireAuth from '../components/RequireAuth';

const Main = lazy(() => import('../pages/private/Main'));
const Quests = lazy(() => import('../pages/private/Quests'));
const NewQuests = lazy(() => import('../pages/private/Quests/NewQuests'));
const QuestsTransactions = lazy(
  () => import('../pages/private/Quests/QuestsHistory')
);
const Partners = lazy(() => import('../pages/private/Partners'));
const Chats = lazy(() => import('../pages/private/Chats'));
const Transactions = lazy(() => import('../pages/private/Transactions'));
const Support = lazy(() => import('../pages/private/Support'));
const CreateTicket = lazy(
  () => import('../pages/private/Support/CreateTicket')
);
const Tickets = lazy(() => import('../pages/private/Support/Tickets'));
const Ticket = lazy(() => import('../pages/private/Support/Ticket'));
const Settings = lazy(() => import('../pages/private/Settings'));

const Login = lazy(() => import('../pages/public/Login'));
const Registration = lazy(() => import('../pages/public/Registration'));
const ForgotPassword = lazy(() => import('../pages/public/ForgotPassword'));

const NotFound = lazy(() => import('../pages/public/404'));

const Router = () => {
  return (
    <Routes>
      <Route path={RouteNames.HOME} element={<Layout />}>
        <Route element={<RequireAuth />}>
          <Route index element={<Main />} />
          <Route path={RouteNames.QUESTS} element={<Quests />}>
            <Route index element={<NewQuests />} />
            <Route
              path={RouteNames.QUESTS_TRANSACTIONS}
              element={<QuestsTransactions />}
            />
          </Route>
          <Route path={RouteNames.PARTNERS} element={<Partners />} />
          <Route path={RouteNames.CHATS} element={<Chats />} />
          <Route path={RouteNames.TRANSACTIONS} element={<Transactions />} />
          <Route path={RouteNames.SUPPORT} element={<Support />}>
            <Route index element={<CreateTicket />} />
            <Route path={RouteNames.TICKETS} element={<Tickets />} />
          </Route>
          <Route path={RouteNames.TICKET} element={<Ticket />} />
          <Route path={RouteNames.SETTINGS} element={<Settings />} />
        </Route>
      </Route>
      <Route path={RouteNames.HOME} element={<AuthLayout />}>
        <Route index element={<Navigate to={RouteNames.LOGIN} replace />} />
        <Route path={RouteNames.LOGIN} element={<Login />} />
        <Route path={RouteNames.REGISTRATION} element={<Registration />} />
        <Route path={RouteNames.FORGOTPASSWORD} element={<ForgotPassword />} />
      </Route>
      <Route path={RouteNames.ANY} element={<NotFound />} />
    </Routes>
  );
};

export default Router;

import classNames from 'classnames';
import ButtonIcon from '../ButtonIcon';
import { ReactComponent as InstagramIcon } from '../../images/icons/instagram.svg';
import { ReactComponent as TelegramIcon } from '../../images/icons/telegram.svg';
import { ReactComponent as VkIcon } from '../../images/icons/vk.svg';
import styles from './SocialWidgetSmall.module.css';

interface Props {
  className?: string;
}
const SocialWidget = ({ className }: Props) => {
  return (
    <div className={classNames(styles['social-widget'], className)}>
      <ButtonIcon
        as="a"
        href="https://instagram.com/skillproquest?igshid=YmMyMTA2M2Y="
        target="_blank"
        appearance="ghost"
        size="medium"
        className={styles['social-widget__button']}
      >
        <InstagramIcon className={styles.icon} />
      </ButtonIcon>
      <ButtonIcon
        as="a"
        href="https://t.me/SkillProQuest"
        target="_blank"
        appearance="ghost"
        size="medium"
        className={styles['social-widget__button']}
      >
        <TelegramIcon className={styles.icon} />
      </ButtonIcon>
      <ButtonIcon
        as="a"
        href="https://vk.com/skillprogame"
        target="_blank"
        appearance="ghost"
        size="medium"
        className={styles['social-widget__button']}
      >
        <VkIcon className={styles.icon} />
      </ButtonIcon>
    </div>
  );
};
export default SocialWidget;

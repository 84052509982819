import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ReactComponent as SpinnerIcon1 } from '../../images/svg/spinner-icon-1.svg';
import { ReactComponent as SpinnerIcon2 } from '../../images/svg/spinner-icon-2.svg';
import { ReactComponent as SpinnerIcon3 } from '../../images/svg/spinner-icon-3.svg';
import { ReactComponent as SpinnerIcon4 } from '../../images/svg/spinner-icon-4.svg';
import styles from './Spinner.module.css';

const icons = [SpinnerIcon1, SpinnerIcon2, SpinnerIcon3, SpinnerIcon4];

const ANIMATION_DURATION = 800;
const ICON_DURATION = ANIMATION_DURATION / icons.length;
const SPIN_DURATION = ANIMATION_DURATION / 1000;

const spinTransition = {
  repeat: Infinity,
  ease: 'linear',
  duration: SPIN_DURATION,
};

interface Props {
  className?: string;
}

const Spinner = ({ className }: Props) => {
  const [activeIcon, setActiveIcon] = useState(0);
  const [dotsCount, setDotsCount] = useState(0);

  const loadingText = `Loading${'.'.repeat(dotsCount)}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      setDotsCount((prevState) => (prevState + 1) % 4);
    }, ICON_DURATION);
    return () => clearTimeout(timer);
  }, [dotsCount]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIcon((prevState) => (prevState + 1) % icons.length);
    }, ICON_DURATION);
    return () => clearTimeout(timer);
  }, [activeIcon]);

  const ActiveIcon = icons[activeIcon];

  return (
    <div className={classNames(styles.spinner, className)}>
      <div className={styles.spinner__content}>
        <div className={styles['spinner__circle-container']}>
          <motion.span
            className={styles.spinner__circle}
            animate={{ rotate: 360 }}
            transition={spinTransition}
          />
          <ActiveIcon className={styles.spinner__icon} />
        </div>
        <span className={styles.spinner__text}>{loadingText}</span>
      </div>
    </div>
  );
};
export default Spinner;

import { toast } from 'react-toastify';
import ButtonIcon from '../ButtonIcon';
import Avatar from '../Avatar';
import IconButton from '../IconButton';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/log-out.svg';
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';
import copyToClipboard from '../../utils/copyToClipboard';
import type { User } from '../../hooks/useUserQuery';
import styles from './HeaderDesktop.module.css';

interface Props {
  user: User;
  avatarUrl?: string;
  onSettingsClick?: () => void;
  onLogOutClick?: () => void;
}

const HeaderDesktop = ({
  user,
  avatarUrl,
  onSettingsClick,
  onLogOutClick,
}: Props) => {
  const handleCopyClick = () => {
    toast.success(`Адресс ID ${user.first_name} был успешно скопирован!`);
    copyToClipboard(user.personalID);
  };
  return (
    <div className={styles.header}>
      <div className={styles.header__left}>
        <Avatar className={styles.header__button} imageUrl={avatarUrl} />
        <div className={styles['header__user-info']}>
          <span className={styles['header__user-name']}>{user.first_name}</span>
          <div className={styles['header__user-id']}>
            <span className={styles['header__user-id-text']}>
              {`ID: ${user.personalID}`}
            </span>
            <IconButton onClick={handleCopyClick}>
              <CopyIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={styles.header__right}>
        <ButtonIcon
          appearance="secondary"
          size="medium"
          onClick={onSettingsClick}
          className={styles.header__button}
        >
          <SettingsIcon />
        </ButtonIcon>
        <ButtonIcon
          appearance="secondary"
          size="medium"
          onClick={onLogOutClick}
          className={styles.header__button}
        >
          <LogOutIcon />
        </ButtonIcon>
      </div>
    </div>
  );
};
export default HeaderDesktop;

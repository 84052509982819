import { useMedia } from 'react-use';
import Menu from '../Menu';
import SocialWidget from '../SocialWidget';
import Drawer from '../Drawer';
import styles from './MenuDrawer.module.css';

interface Props {
  onClose?: () => void;
  isOpened?: boolean;
}
const MenuDrawer = ({ onClose, isOpened }: Props) => {
  const isTablet = useMedia('(min-width: 768px)');
  return (
    <Drawer
      isOpened={isOpened}
      onClose={onClose}
      width={isTablet ? '284px' : undefined}
    >
      <div className={styles.navigation}>
        <Menu className={styles.navigation__menu} onClick={onClose} />
        <SocialWidget />
      </div>
    </Drawer>
  );
};
export default MenuDrawer;

/* eslint-disable import/prefer-default-export */
import type { Avatar } from '../hooks/useAvatarQuery';
import type { User } from '../hooks/useUserQuery';
import type { PartnersConversion } from '../hooks/usePartnersConversionQuery';
import bonuses from '../configs/bonuses';
import partnerBonuses from '../configs/partnerBonuses';
import DefaultAvatar from '../images/png/default-avatar.png';

export const getAvatar = (avatarArr: Avatar[]): Avatar => {
  const avatar = avatarArr[avatarArr.length - 1];
  return (
    avatar || {
      id: 0,
      user_id: 0,
      avatar: DefaultAvatar,
    }
  );
};

export const getUserBonuses = (user: User) => {
  const userBonuses = bonuses.map((bonus) => ({
    ...bonus,
    value: user[bonus.type],
  }));
  return userBonuses;
};

export const getPartnersBonuses = (partnersConversion: PartnersConversion) => {
  const partnersBonuses = partnerBonuses.map((bonus) => ({
    ...bonus,
    value: partnersConversion[bonus.type],
  }));
  return partnersBonuses;
};
